<template>
  <div class="registerPageBox">
    <!-- 注册的顶部 -->
    <div class="registerTop">
      <div class="typeArea">
        <!-- logo -->
        <h1 class="logo">
          <router-link :to="{ path: '/' }">
            <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
          </router-link>
        </h1>
        <!-- 欢迎登录 -->
        <p>账号注册</p>
      </div>
    </div>
    <!-- 背景图跟注册表 -->
    <div class="registerAndBanner">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-position="left"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="账户名称" prop="userCode">
          <el-input v-model="ruleForm.userCode" placeholder="请设置账号名" />
        </el-form-item>
        <el-form-item label="登录密码" prop="password1">
          <el-input v-model.trim="ruleForm.password1" type="password" placeholder="请设置登录密码" />
        </el-form-item>
        <el-form-item label="密码确认" prop="checkPassword">
          <el-input v-model.trim="ruleForm.checkPassword" type="password" placeholder="请再次输入登录密码" />
        </el-form-item>
        <el-form-item label="公司名称" prop="cmpName">
          <el-input v-model="ruleForm.cmpName" placeholder="请输入营业执照上的公司名称" />
        </el-form-item>
        <el-form-item label="手机号码" prop="phoneNo">
          <el-input v-model="ruleForm.phoneNo" placeholder="请输入联系人手机号" />
        </el-form-item>
        <el-form-item label="验证码" prop="phoneKaptcha">
          <div class="phoneKaptcha">
            <el-input v-model="ruleForm.phoneKaptcha" placeholder="请输入验证码" />
            <span :class="['getCode', disabled ? 'disabledActive' : '']" @click="getCode">{{ codeTitle }}</span>
            <!-- <img :src="ruleForm.codeimg" alt class="imageCode" @click="getVerificationCode()"> -->
          </div>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="ruleForm.agreeProtocol">
            阅读并同意<router-link :to="{ path: '/agreement'}" target="_blank">
              《平台服务协议》
            </router-link>
            和<router-link :to="{ path: '/agreement'}" target="_blank">
              《隐私政策》
            </router-link>
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <span class="resButton" @click="submitForm('ruleForm')">注册</span>
        </el-form-item>
      </el-form>
      <img class="registerImg" src="@/assets/image/registerImg.png" alt="banner">
    </div>
  </div>
</template>

<script>
import { encrypt } from '@/unit/index'
import { register, sendPhoneCode } from '@/http/login'
import { rules, formPattern } from '@/unit/matchFormRegex'
export default {
  data() {
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
      if (!reg.test(value)) {
        callback(new Error('字母、数字和其他符号至少包含2种'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        codeimg: '',
        agreeProtocol: true
      },
      disabled: false,
      codeTitle: '获取验证码',
      rules: {
        kaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur' }],
        cmpUnicode: [...rules.unicode],
        userCode: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { required: true, validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }],
        phoneNo: [...rules.phone],
        phoneKaptcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度应为6个字符', trigger: 'blur' }
        ],
        password1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 点击获取验证码
    getCode() {
      if (this.disabled) return
      if (!this.ruleForm.phoneNo || this.ruleForm.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      sendPhoneCode(this.ruleForm.phoneNo, res => {
        this.disabled = true
        let num = 60
        this.ruleForm.code = res.msg
        this.codeTitle = `${num}s`
        var timer = setInterval(() => {
          if (num <= 1) {
            this.disabled = false
            clearInterval(timer)
            this.codeTitle = '获取验证码'
            return
          }
          num--
          this.codeTitle = `${num}s`
        }, 1000)
      })
    },
    // 点击注册确认按钮
    submitForm(formName) {
      if (!this.ruleForm.agreeProtocol) {
        this.$message.error('请勾选协议框！')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.cmpRoleList = ['02']
          this.ruleForm.password = encrypt(this.ruleForm.password1)
          register(this.ruleForm, () => {
            this.$router.push({ path: '/login' })
            this.$message.success('注册成功！')
          })
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.registerPageBox {
  .registerAndBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .el-form {
      width: 448px;
      margin: 170px auto;
      z-index: 12;

      a {
        color: #0089FF;
        margin: 6px;
      }

      .phoneKaptcha {
        display: flex;
        .getCode {
          display: inline-block;
          height: 38px;
          width: 132px;
          margin-left: 12px;
          border: 0;
          outline: 0;
          color: #fff;
          border-radius: 4px;
          cursor: pointer;
          text-align: center;
          background-color: #f8af00;
        }
        .disabledActive {
          background-color: #ccc;
          color: #f9f9f9;
        }
      }
      .resButton {
        text-align: center;
        height: 40px;
        width: 160px;
        line-height: 40px;
        display: inline-block;
        color: #fff;
        font-size: 14px;
        background-color: $themeColors;
        cursor: pointer;
        margin: 12px 0 0 60px;
      }

      /deep/.el-input__inner {
        height: 38px;
        border: 1px solid #f0f0f0 !important;
      }

      /deep/.el-input__inner:focus {
        border: 1px solid #f0f0f0 !important;
      }
    }

    .registerImg {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 50%;
      width: 100%;
      z-index: -1;
    }
  }

  .registerTop {
    z-index: 36;
    height: 8.3vh;
    background-color: #fff;
    border-bottom: 1px solid #E3E3E3;

    .typeArea {
      display: flex;
      align-items: center;
      height: 100%;
      width: $centerPlateWidth;
      margin: auto;
      color: $fontColor4;
      font-size: 18px;

      .logo {
        // width: 229px;
        height: 5.6vh;
        margin-right: 43px;
        z-index: 4545;
        a,
        img {
          height: 100%;
          width: 100%;
          display: inline-block;
        }
      }
    }
  }
}
</style>
